.theme2 {
  --primary-100: #c2c5cc;
  --primary-200: #acb0ba;
  --primary-300: #969ba7;
  --primary-400: #7f8694;
  --primary-500: #6b7280;
  --primary-600: #515761;
  --primary-700: #383c43;
  --primary-800: #1e2024;
  --primary-900: #050506;

  --accent-100: #fbdca8;
  --accent-200: #facd81;
  --accent-300: #f8bd59;
  --accent-400: #f7ae32;
  --accent-500: #f59e0b;
  --accent-600: #c07c08;
  --accent-700: #8a5906;
  --accent-800: #543603;
  --accent-900: #1e1401;

  --secondary-100: #e6fffa;
  --secondary-200: #b2f5ea;
  --secondary-300: #81e6d9;
  --secondary-400: #4fd1c5;
  --secondary-500: #38b2ac;
  --secondary-600: #319795;
  --secondary-700: #2c7a7b;
  --secondary-800: #285e61;
  --secondary-900: #234e52;

  --neutral-100: #f7fafc;
  --neutral-200: #edf2f7;
  --neutral-300: #e2e8f0;
  --neutral-400: #cbd5e0;
  --neutral-500: #a0aec0;
  --neutral-600: #718096;
  --neutral-700: #4a5568;
  --neutral-800: #2d3748;
  --neutral-900: #1a202c;

  --light-100: #ffffff;
}
