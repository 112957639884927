.theme1 {
  --primary-100: #34a6fc;
  --primary-200: #0c94fc;
  --primary-300: #037edb;
  --primary-400: #0367b3;
  --primary-500: #02508b;
  --primary-600: #013054;
  --primary-700: #00101c;
  --primary-800: #000000;
  --primary-900: #000000;

  --accent-100: #fee4c9;
  --accent-200: #fed1a0;
  --accent-300: #febd78;
  --accent-400: #fda94f;
  --accent-500: #fd9527;
  --accent-600: #ea7902;
  --accent-700: #b25c02;
  --accent-800: #7b4001;
  --accent-900: #432301;

  --secondary-100: #e6fffa;
  --secondary-200: #b2f5ea;
  --secondary-300: #81e6d9;
  --secondary-400: #4fd1c5;
  --secondary-500: #38b2ac;
  --secondary-600: #319795;
  --secondary-700: #2c7a7b;
  --secondary-800: #285e61;
  --secondary-900: #234e52;

  --neutral-100: #f7fafc;
  --neutral-200: #edf2f7;
  --neutral-300: #e2e8f0;
  --neutral-400: #cbd5e0;
  --neutral-500: #a0aec0;
  --neutral-600: #718096;
  --neutral-700: #4a5568;
  --neutral-800: #2d3748;
  --neutral-900: #1a202c;

  --light-100: #ffffff;
}
