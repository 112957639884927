/* General */
.revelo-slider-v2,
.revelo-slider-v2__thumbs {
  --swiper-theme-color: #161038;
  --swiper-navigation-sides-offset: 0;
  --swiper-navigation-size: 20px;
  --neutral-100: #f4f6f6;
}

.slider__container {
  height: 100%;
}

.swiper-slide-zoomed .zoom-hint {
  opacity: 0;
}

.revelo-slider-v2,
.revelo-slider-v2 .swiper-slide {
  height: auto;
}

.revelo-slider-v2 img {
  width: 100%;
}

.revelo-slider-v2__thumbs .swiper-slide {
  border: 1px solid var(--neutral-200);
}
.revelo-slider-v2__thumbs .swiper-slide:hover,
.revelo-slider-v2 .slider__bullet--active:hover {
  border: 1px solid #FFBCDF;
  opacity: 1;
}

.revelo-slider-v2__thumbs .swiper-slide-thumb-active {
  border: 1px solid var(--neutral-950);
}

.revelo-slider-v2 .slider__bullet--active {
  background-color: var(--neutral-950);
  opacity: 1;
}

/* Navigation */
.revelo-slider-v2 .swiper-button-prev,
.revelo-slider-v2__thumbs .swiper-button-prev {
  justify-self: start;
}

.revelo-slider-v2 .swiper-button-next,
.revelo-slider-v2__thumbs .swiper-button-next {
  justify-self: end;
}

.revelo-slider-v2 [class^='swiper-button-'],
.revelo-slider-v2__thumbs [class^='swiper-button-'] {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(43, 55, 61, 0.10);
  padding: 8px;
  transition: background-color 300ms ease-in;
}

.revelo-slider-v2 [class^='swiper-button-']:hover,
.revelo-slider-v2 [class^='swiper-button-']:active,
.revelo-slider-v2__thumbs [class^='swiper-button-']:hover,
.revelo-slider-v2__thumbs [class^='swiper-button-']:active {
  background-color: #FFBCDF;
}

.revelo-slider-v2 .swiper-button-next:after,
.revelo-slider-v2 .swiper-button-prev:after {
  font-size: 20px;
}


@media (max-width: 767px) {
  .revelo-slider-v2__thumbs .swiper-button-next,
  .revelo-slider-v2__thumbs .swiper-button-prev {
    display: none;
  }
}

.revelo-slider-v2-outer,
.revelo-slider-v2-outer__thumbs {
  width: calc(100% - 40px);
}

.modal-fullscreen-image .revelo-slider-v2 {
  height: 100% !important;
}

.modal-fullscreen-image .slider__slides {
  height: 85%;
}

.modal-fullscreen-image .slider__thumbs {
  padding: 0 8px;
  max-width: 660px;
  margin-left: auto;
  margin-right: auto;
}

.modal-fullscreen-image .swiper-slide-zoomed .swiper-zoom-container > img {
  cursor: zoom-out;
}
