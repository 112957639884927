.default {
  --primary-50: #fff0f8;
  --primary-100: #ffcce7;
  --primary-200: #ffbcdf;
  --primary-300: #ff8ac5;
  --primary-400: #e471ab;
  --primary-500: #de549a;
  --primary-600: #d21e79;
  --primary-700: #bc005f;
  --primary-800: #9a014e;
  --primary-900: #79003d;
  --primary-950: #520029;

  --accent-50: #ffffcc;
  --accent-100: #ffff99;
  --accent-200: #ffff66;
  --accent-300: #ffff0a;
  --accent-400: #fafa00;
  --accent-500: #ebeb00;
  --accent-600: #dbdb00;
  --accent-700: #cccc00;
  --accent-800: #a3a300;
  --accent-900: #7a7a00;
  --accent-950: #525200;

  --secondary-50: #eef8ff;
  --secondary-100: #d8eeff;
  --secondary-200: #b9e0ff;
  --secondary-300: #89cfff;
  --secondary-400: #52b4ff;
  --secondary-500: #2a91ff;
  --secondary-600: #0d6efd;
  --secondary-700: #0c5ae9;
  --secondary-800: #1149bc;
  --secondary-900: #144194;
  --secondary-950: #11295a;

  --neutral: #ffffff;
  --neutral-50: #f4f5f5;
  --neutral-100: #eaebec;
  --neutral-200: #d5d7d8;
  --neutral-300: #bfc3c5;
  --neutral-400: #aaafb1;
  --neutral-500: #929b9e;
  --neutral-600: #80878b;
  --neutral-700: #6b7478;
  --neutral-800: #555f64;
  --neutral-900: #404b50;
  --neutral-950: #2b373d;

  --neutral-alpha-15: rgba(255, 255, 255, 0.15);
  --neutral-alpha-50: rgba(255, 255, 255, 0.5);

  --error-100: var(--danger-700);
  --error-200: #ffddd3;
  --error-400: #dc2626;

  --danger-50: #ffeeeb;
  --danger-100: #fee2dc;
  --danger-200: #fed2c9;
  --danger-300: #fdb2a4;
  --danger-400: #fc765c;
  --danger-500: #ff4529;
  --danger-600: #ff4529;
  --danger-700: #dc001c;
  --danger-800: #b80018;
  --danger-900: #990014;
  --danger-950: #52000b;

  --light-100: #ffffff;
  --light-150: #f4f7f7;
  --light-200: rgba(22, 16, 56, 0.06);

  --highlight-100: #cdfedc;

  --text-color: var(--neutral-950);
  --text-color-light: #fffcfa;

  --swiper-theme-color: #0f4a48;
  --swiper-navigation-size: 20px;

  --button-primary-hover: #fdc6e4;
  --button-secondary-hover: #4b555a;
}
