@media only screen and (max-width: 767px) {
  .buybox-wrapper.sticky {
    position: fixed;
    top: initial;
    bottom: -118px;
    width: 100%;
    left: 0;
    margin: 0;
    padding: 16px;
    -webkit-transition: bottom 300ms;
    transition: bottom 300ms;
    box-shadow: 0 -2px 5px 0 #6b72802b;
  }

  .buybox-wrapper.stuck {
    top: initial;
    bottom: 0;

    background-color: white;
  }

  .buybox-wrapper.sticky .price-wrapper {
    display: flex;
  }
}