.offcanvas {
  transform: translateX(100%);
}

.offcanvas-transition-enter-done {
  transform: none;
}

.offcanvas-transition-exit {
  transform: translateX(100%);
}
