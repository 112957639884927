.revelo-slider .swiper-button-prev,
.revelo-slider .swiper-rtl .swiper-button-next {
  left: 0;
}

.revelo-slider .swiper-button-next,
.revelo-slider .swiper-rtl .swiper-button-prev {
  right: 0;
}

.revelo-slider__thumbs .swiper-slide {
  border: 1px solid var(--primary-100);
}

.revelo-slider,
.revelo-slider__thumbs {
  --swiper-theme-color: #161038;
}

.swiper-slide-zoomed .zoom-hint {
  opacity: 0;
}
