.revelo-tooltip-container .revelo-tooltip {
  background-color: var(--neutral-100);
  border-radius: 5px;
  padding: 16px;
  width: 200px;
  color: var(--text-color);
}

.revelo-tooltip-container .revelo-tooltip .interaction-area {
  position: absolute;
  top: -20px;
  left: -30px;
  width: calc(100% + 50px);
  height: calc(100% + 40px);
  z-index: 999;
}
