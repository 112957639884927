.revelo-slider-product {
  --swiper-pagination-bullet-width: 12px;
  --swiper-pagination-bullet-height: 12px;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-color: theme('colors.neutral.950');
  --swiper-pagination-bullet-inactive-color: theme('colors.neutral.200');
  --swiper-pagination-bullet-inactive-opacity: 1;
}

.revelo-slider-product .swiper-pagination-bullet.slider__bullet--active {
  background-color: theme('colors.neutral.950');
}

.revelo-slider-product-outer,
.revelo-slider-product-outer__thumbs {
  position: static !important;
  display: grid;
  width: calc(100% - 100px);
}

.revelo-slider-product .swiper-button-next {
  right: 0;
}

.revelo-slider-product .swiper-button-prev {
  left: 0;
}

.revelo-slider-product .swiper-button-next,
.revelo-slider-product .swiper-button-prev {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(43, 55, 61, 0.10);
  padding: 8px;
}

.revelo-slider-product .swiper-button-next:after,
.revelo-slider-product .swiper-button-prev:after {
  font-size: 20px;
}

.revelo-slider-product .swiper-button-next:hover,
.revelo-slider-product .swiper-button-prev:hover {
  background-color: #FFBCDF;
}

.revelo-slider-product .swiper-horizontal > .swiper-pagination-bullets,
.revelo-slider-product .swiper-pagination-bullets.swiper-pagination-horizontal,
.revelo-slider-product .swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
}
